import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://learn-mobo-641381d4670b.herokuapp.com/api'
    : 'http://localhost:8000/api',
  withCredentials: true
});

// Function to get CSRF token from cookies
function getCsrfToken() {
  const name = 'csrftoken';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Add an interceptor to set the CSRF token and authorization token
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  const csrfToken = getCsrfToken();
  if (csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

export const loginUser = async (userData) => {
  try {
    console.log('Attempting login with:', JSON.stringify(userData));
    const response = await api.post('/accounts/login/', userData);
    console.log('Login response:', response.data);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      api.defaults.headers.common['Authorization'] = `Token ${response.data.token}`;
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error.response ? error.response.data : error);
    throw error;
  }
};

export const fetchProtectedData = async () => {
  try {
    const response = await api.get('/accounts/protected/');
    return response.data;
  } catch (error) {
    console.error('Error fetching protected data', error.response ? error.response.data : error);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    await api.post('/accounts/logout/');
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
  } catch (error) {
    console.error('Logout error:', error.response ? error.response.data : error);
  }
};

export const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem('token', token);
    api.defaults.headers.common['Authorization'] = `Token ${token}`;
  } else {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
  }
};