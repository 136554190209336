import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, loggedIn }) => {
  const location = useLocation();
  
  console.log('ProtectedRoute - loggedIn:', loggedIn, 'path:', location.pathname);

  if (!loggedIn) {
    console.log('ProtectedRoute - Redirecting to login');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  console.log('ProtectedRoute - Rendering protected content');
  return children;
};

export default ProtectedRoute;