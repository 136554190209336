import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import SafeSexLesson from './components/SafeSexLesson/SafeSexLesson';
import ConsentAndHealthyRelationshipsLesson from './components/ConsentAndHealthyRelationshipsLesson/ConsentAndHealthyRelationshipsLesson';
import FlashCards from './components/FlashCards/FlashCards';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Toolbox from './components/Toolbox/Toolbox';
import Settings from './components/Settings/Settings';
import LessonPlans from './components/LessonPlans/LessonPlans';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { loginUser } from './api';
import './App.scss';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const isPlatform = window.location.hostname === 'platform.learnmobo.com' || 
                       (process.env.NODE_ENV !== 'production' && token);
    console.log('App useEffect - token:', !!token, 'isPlatform:', isPlatform);
    setLoggedIn(!!token);
    if (isPlatform && !token) {
      console.log('Redirecting to login');
      navigate('/login');
    } else if (token) {
      console.log('Token exists, staying on current page');
    }
  }, [navigate]);

  const handleLogin = async (userData) => {
    try {
      const response = await loginUser(userData);
      if (response.token) {
        setLoggedIn(true);
        localStorage.setItem('token', response.token);
        
        // Use React Router's navigate for both production and development
        navigate('/dashboard');
        
        return { success: true, message: 'Login successful' };
      } else {
        // Handle case where token is not received
        return { success: false, message: 'Login failed: No token received' };
      }
    } catch (error) {
      console.error('Login failed:', error);
      return { 
        success: false, 
        message: error.response?.data?.error || 'An unexpected error occurred'
      };
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
    localStorage.removeItem('token');
    navigate('/login');
  };

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
  };

  return (
    <div className="appContainer">
      <ScrollToTop />
      <Header loggedIn={loggedIn} onLogout={handleLogout} />
      <div className="content">
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/dashboard" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/toolbox" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <Toolbox />
            </ProtectedRoute>
          } />
          <Route path="/lesson-plans" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <LessonPlans />
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <Settings />
            </ProtectedRoute>
          } />
          <Route path="/safe-sex-lesson" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <SafeSexLesson />
            </ProtectedRoute>
          } />
          <Route path="/consent-and-healthy-relationships-lesson" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <ConsentAndHealthyRelationshipsLesson />
            </ProtectedRoute>
          } />
          <Route path="/flash-cards" element={
            <ProtectedRoute loggedIn={loggedIn}>
              <FlashCards />
            </ProtectedRoute>
          } />
          <Route path="/" element={<Navigate to={loggedIn ? "/dashboard" : "/login"} />} />
          <Route path="*" element={<Navigate to={loggedIn ? "/dashboard" : "/login"} />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;