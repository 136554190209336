import React from 'react';
import AnalyticsDashboard from './AnalyticsDashboard';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <div className={styles.dashboard}>
      <header className={styles.header}>
        <div className={styles.greeting}>
          <h1>Welcome, Administrator</h1>
          <p>{currentDate}</p>
        </div>
        <div className={styles.summary}>
          <div className={styles.metric}>
            <h3>Total Students</h3>
            <p>1,234</p>
          </div>
          <div className={styles.metric}>
            <h3>Average Score</h3>
            <p className={styles.warning}>48%</p>
          </div>
          <div className={styles.metric}>
            <h3>Active Courses</h3>
            <p>15</p>
          </div>
        </div>
      </header>
      <main className={styles.main}>
        <h2>Student Performance Analytics</h2>
        <p>Review the latest assessment results and insights to identify areas for improvement in the curriculum.</p>
        <AnalyticsDashboard />
      </main>
    </div>
  );
};

export default Dashboard;