import React from 'react';

const BarChart = ({ data, maxValue, color }) => (
  <div className="bar-chart">
    {data.map((item) => (
      <div key={item.label} className="bar-item">
        <div className="bar-label">{item.label}</div>
        <div className="bar-container">
          <div 
            className="bar" 
            style={{ 
              width: `${(item.value / maxValue) * 100}%`, 
              backgroundColor: item.value < 50 ? '#DB4437' : color 
            }}
          >
            <span className="bar-value">{item.value}%</span>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const InsightCard = ({ title, content, type }) => (
  <div className={`insight-card ${type}`}>
    <h4>{title}</h4>
    <p>{content}</p>
  </div>
);

const AnalyticsDashboard = () => {
  const assessmentData = [
    { label: 'Anatomy', value: 45 },
    { label: 'Contraception', value: 30 },
    { label: 'STIs', value: 55 },
    { label: 'Consent', value: 70 },
    { label: 'Relationships', value: 40 },
  ];

  const groupProgressData = [
    { label: 'Group A', value: 80 },
    { label: 'Group B', value: 65 },
    { label: 'Group C', value: 90 },
  ];

  const insights = [
    { title: 'Critical Gap', content: 'Contraception knowledge is significantly below target. Immediate intervention recommended.', type: 'warning' },
    { title: 'Strength Area', content: 'Consent understanding is relatively strong. Consider using peer mentors to reinforce this topic.', type: 'success' },
    { title: 'Trend', content: 'Overall scores have decreased by 15% compared to last semester. Review curriculum and teaching methods.', type: 'info' },
  ];

  return (
    <div className="analytics-dashboard">
      <div className="chart-container">
        <h3>Assessment Results by Topic</h3>
        <BarChart data={assessmentData} maxValue={100} color="#4285F4" />
      </div>

      <div className="insights-container">
        <h3>Key Insights</h3>
        {insights.map((insight, index) => (
          <InsightCard key={index} {...insight} />
        ))}
      </div>

      <div className="chart-container full-width">
        <h3>Group Progress on Current Curriculum</h3>
        <BarChart data={groupProgressData} maxValue={100} color="#0F9D58" />
      </div>

      <style jsx>{`
        .analytics-dashboard {
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
        }
        .chart-container, .insights-container {
          flex: 1;
          min-width: 300px;
        }
        .full-width {
          width: 100%;
        }
        h3 {
          font-size: 1.2rem;
          font-weight: 500;
          color: #333;
          margin-bottom: 20px;
        }
        .bar-chart {
          width: 100%;
        }
        .bar-item {
          margin-bottom: 15px;
        }
        .bar-label {
          margin-bottom: 5px;
          font-weight: 500;
        }
        .bar-container {
          background-color: #f1f3f4;
          border-radius: 4px;
          overflow: hidden;
          height: 30px;
        }
        .bar {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 10px;
          color: white;
          font-weight: 500;
          transition: width 0.5s ease-in-out;
        }
        .bar-value {
          font-size: 0.9rem;
        }
        .insight-card {
          background-color: #f8f9fa;
          border-left: 4px solid;
          padding: 15px;
          margin-bottom: 15px;
          border-radius: 4px;
        }
        .insight-card h4 {
          margin: 0 0 10px 0;
          font-size: 1rem;
        }
        .insight-card p {
          margin: 0;
          font-size: 0.9rem;
          color: #555;
        }
        .insight-card.warning {
          border-color: #DB4437;
        }
        .insight-card.success {
          border-color: #0F9D58;
        }
        .insight-card.info {
          border-color: #4285F4;
        }
      `}</style>
    </div>
  );
};

export default AnalyticsDashboard;