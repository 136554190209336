import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';

const Header = ({ loggedIn, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('Header component rendered. loggedIn:', loggedIn);
    console.log('Current path:', location.pathname);
  }, [loggedIn, location.pathname]);

  const handleLogout = (e) => {
    e.preventDefault();
    console.log('Logout clicked');
    onLogout();
  };

  const handleNavClick = (path) => (e) => {
    e.preventDefault();
    console.log('Navigation clicked:', path);
    navigate(path);
  };

  return (
    <header className={styles.header}>
      <a href={loggedIn ? "/dashboard" : "/"} className={styles.logo} onClick={handleNavClick(loggedIn ? "/dashboard" : "/")}>
        <img src={process.env.PUBLIC_URL + '/MoboLogo.png'} alt="Mobo Logo" className={styles.logoImage} />
      </a>
      <nav className={styles.nav}>
        {loggedIn ? (
          <>
            <a href="/dashboard" className={styles.navLink} onClick={handleNavClick('/dashboard')}>Home</a>
            <a href="/dashboard/toolbox" className={styles.navLink} onClick={handleNavClick('/dashboard/toolbox')}>Toolbox</a>
            <a href="/lesson-plans" className={styles.navLink} onClick={handleNavClick('/lesson-plans')}>Lesson Plans</a>
            <a href="/settings" className={styles.navLink} onClick={handleNavClick('/settings')}>Settings</a>
            <button onClick={handleLogout} className={styles.navLink}>Log Out</button>
          </>
        ) : (
          <a href="/login" className={styles.specialButton} onClick={handleNavClick('/login')}>Log In</a>
        )}
      </nav>
    </header>
  );
};

export default Header;